const columns = Object.freeze([
  {
    key: 'select',
  },
  {
    key: 'name',
    label: 'Службы',
    thClass: 'w-50 text-center align-middle',
    tdClass: 'text-center',
  },
  {
    key: 'sort',
    label: 'Порядок сортировки',
    sortable: true,
    thClass: 'w-40 text-center align-middle',
    tdClass: 'text-center',
  },
  {
    key: 'status',
    label: 'Статус',
    sortable: true,
    thClass: 'w-10 text-center align-middle',
    tdClass: 'd-flex justify-content-center',
  },
  {
    key: 'actions',
    label: '',
    thClass: 'text-center align-middle',
    tdClass: 'text-center align-middle',
  },
])

const modal = {
  delete: {
    title: 'Вы действительно хотите удалить?',
    content: 'Вы действительно хотите удалить',
    visible: false,
    okTitle: 'Удалить',
  },
  image: {
    title: 'Фото чека',
    visible: false,
    okTitle: 'Закрыть',
  },
  edit: {
    title: 'Редактирование курьерской службы',
    visible: false,
    okTitle: 'Изменить',
  },
  create: {
    title: 'Добавление курьерской службы',
    visible: false,
    okTitle: 'Создать',
  },
  status: {
    title: 'Добавление курьерской службы',
    visible: false,
    okTitle: 'Создать',
  },
}

export { columns, modal }

